<template>
  <div
    class="fixed inset-0 z-50 grid place-content-center bg-gradient-light dark:bg-gradient-dark"
  >
    <div class="w-screen max-w-96 p-4">
      <sovi-brand-title :logo-size="48" class="mb-8" />

      <v-progress-linear
        class="mt-6"
        :color="theme.current.value.dark ? 'primary' : 'tertiary'"
        :bg-color="
          theme.current.value.dark
            ? 'rgba(255,255,255,0.1)'
            : 'rgba(255,255,255,0.5)'
        "
        bg-opacity="1"
        indeterminate
      />

      <p class="text-right text-sm">
        {{ message ? t(message) : t('loading') }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTheme } from 'vuetify';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<{
  message?: string;
}>();

const theme = useTheme();
</script>

<i18n lang="json">
{
  "fi": {
    "loading": "Odota hetki...",
    "authenticating": "Haetaan tunnistautumistietoja..."
  },
  "en": {
    "loading": "Please wait...",
    "authenticating": "Loading identification details..."
  }
}
</i18n>
