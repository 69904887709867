import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import type { AddNotificationParams, Notification } from '@/types/notification';

export const DEFAULT_NOTIFICATION_COLOR = '#eca1c2';
export const DEFAULT_NOTIFICATION_ICON = 'mdi-information';

export const useNotificationStore = defineStore('notification', () => {
  const showNotification = ref(false);

  const notification = ref<Notification>({} as Notification);

  const queue = ref<Notification[]>([]);

  const addNotification = (payload: AddNotificationParams) => {
    queue.value = [
      ...queue.value,
      {
        ...payload,
        id: Date.now(),
        icon: payload.icon || DEFAULT_NOTIFICATION_ICON,
        color: payload.color || DEFAULT_NOTIFICATION_COLOR,
        persistent: payload.persistent || false,
      },
    ];
  };

  const removeNotification = (notificationId: number) => {
    setTimeout(() => {
      queue.value = queue.value.filter((item) => item.id !== notificationId);
    }, 500);
  };

  watch(
    queue,
    (line) => {
      if (!line.length) {
        showNotification.value = false;
        notification.value = {} as Notification;

        return;
      }

      [notification.value] = line;
      showNotification.value = true;
    },
    { immediate: true },
  );

  return {
    notification,
    showNotification,
    queue,
    addNotification,
    removeNotification,
  };
});
