<template>
  <sovi-dialog
    :show="display"
    :title="t('companyRequest')"
    @close="emit('cancel')"
    @submit="onCompanyRequestSubmit"
  >
    <div class="grid gap-4">
      <p>{{ t('companyRequestContent') }}</p>

      <v-form
        ref="formRef"
        v-model="valid"
        class="grid gap-4"
        @submit.prevent="onCompanyRequestSubmit()"
      >
        <v-text-field
          v-model="contactForm.companyName"
          :label="t('companyName')"
          :rules="rules.companyName"
          color="tertiary"
          autocomplete="off"
          name="companyName"
          prepend-inner-icon="mdi-domain"
        />

        <v-text-field
          v-model="contactForm.businessId"
          :label="t('businessId')"
          :rules="rules.businessId"
          color="tertiary"
          autocomplete="off"
          name="businessId"
          prepend-inner-icon="mdi-pound"
        />

        <v-text-field
          v-model="contactForm.phone"
          :label="t('phone')"
          :rules="rules.phone"
          color="tertiary"
          autocomplete="off"
          name="phone"
          prepend-inner-icon="mdi-phone"
        />
      </v-form>
    </div>

    <template #actions="{ onClose }">
      <v-spacer />

      <v-btn variant="text" color="" rounded @click="onClose">
        {{ t('cancel') }}
      </v-btn>

      <v-btn
        :disabled="submitting"
        :loading="submitting"
        color="primary"
        type="submit"
        variant="elevated"
        rounded
      >
        {{ t('ok') }}
      </v-btn>
    </template>
  </sovi-dialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { accessRepository } from '@/repositories';
import { required, phone } from '@/validators';
import { useNotificationStore } from '@/stores/notification';
import type { VForm } from 'vuetify/components';

withDefaults(
  defineProps<{
    display?: boolean;
  }>(),
  { display: false },
);

const { t } = useI18n();

const { addNotification } = useNotificationStore();

const emit = defineEmits(['submit', 'cancel']);

const valid = ref(false);

const submitting = ref(false);

const rules = computed(() => ({
  phone: [required(t('requiredValue')), phone(t('invalidValue'))],
  companyName: [required(t('requiredValue'))],
  businessId: [required(t('requiredValue'))],
}));

const contactForm = ref({
  companyName: '',
  businessId: '',
  phone: '',
});

const formRef = ref<InstanceType<typeof VForm> | null>(null);

const onCompanyRequestSubmit = async () => {
  formRef.value?.validate();

  if (!valid.value) return;

  submitting.value = true;

  const response = await accessRepository.existingUser(contactForm.value);

  submitting.value = false;

  if (!response.success) {
    addNotification({
      title: t('requestError'),
      body: t('requestErrorBody'),
      persistent: true,
      icon: 'mdi mdi-alert',
      color: 'error',
    });

    return;
  }

  addNotification({
    title: t('requestSuccess'),
    icon: 'mdi mdi-check-circle',
    persistent: true,
  });

  emit('cancel');

  formRef.value?.reset();
};
</script>

<style lang="scss"></style>

<i18n lang="json">
{
  "fi": {
    "companyName": "Yrityksen nimi",
    "businessId": "Y-tunnus",
    "phone": "Puhelinnumero",
    "cancel": "Peruuta",
    "ok": "Lähetä",
    "requiredValue": "Vaadittu tieto",
    "invalidValue": "Virheellinen arvo",
    "companyRequest": "Yritystoiminnot",
    "requestError": "Hakemus epäonnistui",
    "requestErrorBody": "Yritä myöhemmin uudelleen",
    "requestSuccess": "Hakemus lähetetty",
    "companyRequestContent": "Tarvitsetko asiakaspintaan personoidun palvelun yrityksesi omalla logolla? Lähetä yrityksesi tiedot oheisella lomakkeella, niin kytkemme yritystoiminnot käyttöösi."
  },
  "en": {
    "companyName": "Company name",
    "businessId": "Business ID",
    "phone": "Phone number",
    "cancel": "Cancel",
    "ok": "Send",
    "requiredValue": "Required field",
    "invalidValue": "Invalid value",
    "companyRequest": "Company features",
    "requestError": "Application failed",
    "requestErrorBody": "Try again later",
    "requestSuccess": "Application sent",
    "companyRequestContent": "Need for a personalized service with company logo? Please send your company details and we will unlock all business features at your disposal."
  }
}
</i18n>
