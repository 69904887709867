import { computed, ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { id } from 'vuetify/locale';
import type {
  CreateLogoPayload,
  DeleteDocumentPayload,
  Document,
} from '@/types/document';
import { useRoute } from 'vue-router';
import { useAuthorizationStore } from '@/stores/authorization';
import { formatDate } from '@/utils';
import { documentRepository } from '@/repositories';
import { useLoadingStore } from './loading';

/**
 * Converts a long string of bytes into a readable format e.g KB, MB, GB, TB, YB
 *
 * @param {Int} num The number of bytes.
 */
const toReadableBytes = (bytes: number) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  return `${+(bytes / 1024 ** i).toFixed(2) * 1} ${sizes[i]}`;
};

export const useDocumentStore = defineStore('document', () => {
  const documents = ref<Record<string, Document[]>>({});
  const showAddDialog = ref(false);
  const urls = ref<
    {
      id: number;
      name: string;
      url: string;
    }[]
  >([]);
  const verifyData = ref({});

  const route = useRoute();

  const { user } = storeToRefs(useAuthorizationStore());

  const { startLoading, endLoading } = useLoadingStore();

  const getDocumentsByContractId = computed(
    () => (contractId: string) =>
      id && documents.value[contractId] ? documents.value[contractId] : [],
  );

  const getDocumentsOfCurrentUser = computed(() =>
    getDocumentsByContractId
      .value(route.params.contractId as string)
      .filter((d) =>
        d.participants.some((p) => p.participant_id === user.value?.id),
      ),
  );

  const documentsSignedByUser = computed(() =>
    getDocumentsOfCurrentUser.value.every(
      (d) =>
        d.participants.find((p) => p.participant_id === user.value?.id)
          ?.signature !== null,
    ),
  );

  const signedAt = computed(() => {
    if (
      !documentsSignedByUser.value ||
      !getDocumentsOfCurrentUser.value?.[0]?.participants
    ) {
      return null;
    }

    return formatDate(
      getDocumentsOfCurrentUser.value[0]?.participants.find(
        (p) => p.participant_id === user.value?.id,
      )?.signed_at || '',
      { type: 'full', locale: 'fi' },
    );
  });

  const createLogo = async ({ payload, scheme }: CreateLogoPayload) => {
    await documentRepository.createLogo(scheme, payload);
  };

  const deleteDocument = async (payload: DeleteDocumentPayload) => {
    startLoading('deleteDocument');
    await documentRepository.delete(payload);
    endLoading('deleteDocument');
  };

  const getUrls = async (payload: { contractId: string; ids: string }) => {
    urls.value = [];

    const { data } = await documentRepository.getUrls(
      payload.contractId,
      payload.ids,
    );

    urls.value = data.documents;
  };

  const listDocuments = async (contractId: string) => {
    startLoading('listDocuments');

    const {
      data: { documents: list },
    } = await documentRepository.getAll(contractId);

    documents.value = {
      [contractId]: list.map((d) => ({
        ...d,
        formattedSize: toReadableBytes(d.size),
      })),
    };

    endLoading('listDocuments');
  };

  const getVerifiedDocuments = async ({
    contractId,
  }: {
    contractId: string;
  }) => {
    const response = await documentRepository.getVerifiedDocuments(contractId);

    verifyData.value = response.data;
  };

  const getAllLogos = () => documentRepository.getAllLogos();

  const download = ({
    contractId,
    documentId,
  }: {
    contractId: string;
    documentId: number;
  }) => documentRepository.getOne(contractId, documentId);

  return {
    documents,
    showAddDialog,
    urls,
    verifyData,
    getDocumentsByContractId,
    documentsSignedByUser,
    signedAt,
    createLogo,
    deleteDocument,
    getUrls,
    listDocuments,
    getVerifiedDocuments,
    getAllLogos,
    download,
  };
});
