import Client from '@/repositories/clients/fetchClient';
import type {
  CompanyRequestParameters,
  ContactFormParameters,
} from '@/types/access';

const resource = 'access';

export const accessRepository = {
  add: (payload: ContactFormParameters) => Client.post(resource, payload),
  verify: (token: string) => Client.get(`${resource}/verify/${token}`),
  existingUser: (payload: CompanyRequestParameters) =>
    Client.post(`${resource}/existing-user`, payload),
};
