import { ref, computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import type {
  CreateMagicLinkPayload,
  SendLinksPayload,
  TokenLoginPayload,
  User,
} from '@/types/authorization';
import {
  authorizationRepository,
  magicLinkRepository,
  participantRepository,
} from '@/repositories';
import type { CreateCompanyChangeRequestPayload } from '@/types/company';
import { useLoadingStore } from './loading';
import { useOnboardingStore } from './onboarding';

type ContractLogo = {
  upload_name: string;
  scheme: 'light' | 'dark';
};

export const useAuthorizationStore = defineStore('authorization', () => {
  const { startLoading, endLoading } = useLoadingStore();

  const authorized = ref(false);
  const contractLogos = ref<ContractLogo[]>([]);
  const linkParticipantName = ref('');
  const netsPerson = ref(false);
  const sessionUpdated = ref(null);
  const showSendDialog = ref(false);
  const user = ref<User>({} as User);

  const userEmail = computed(() => user.value.email);

  let sessionId: NodeJS.Timeout;

  /**
   * Check if user is authorized
   */
  const isAuthorized = async () => {
    startLoading('isAuthorized');

    const { seenTours, showWelcomeDialog, showFirstContractDialog } =
      storeToRefs(useOnboardingStore());

    showWelcomeDialog.value = false;
    showFirstContractDialog.value = false;

    const response = await authorizationRepository.isAuthorized();

    clearInterval(sessionId);

    sessionId = setInterval(
      async () => {
        const sessionResponse = await authorizationRepository.session();

        if ('success' in sessionResponse && !sessionResponse.data.updated) {
          clearInterval(sessionId);
        }
      },
      1000 * 60 * 15,
    );

    if ('success' in response) {
      authorized.value = !!response.data.authorized;
      user.value = response.data.participant;
      netsPerson.value = !!response.data.nets_person;
      linkParticipantName.value = response.data.link_participant_name;
      contractLogos.value = response.data.contract_logos;

      if (user.value.admin) user.value.admin = +user.value.admin;

      seenTours.value = (response.data.tours ?? []).map(
        (tourItem) => tourItem.tour_name,
      );

      if (!seenTours.value.includes('welcomeTour') && authorized.value) {
        showWelcomeDialog.value = true;
      }

      if (!seenTours.value.includes('newContractTour') && authorized.value) {
        showFirstContractDialog.value = true;
      }

      const participantId = response.data?.participant?.id;

      if (
        participantId &&
        localStorage.getItem('sovi-participant') !== participantId.toString()
      ) {
        localStorage.setItem('sovi-participant', participantId.toString());
        localStorage.setItem(
          'sovi-identifier',
          new Date().getTime().toString(),
        );
      }

      return response.data;
    }

    endLoading('isAuthorized');

    return { isAuthorized: false };
  };

  const create = async (payload: CreateMagicLinkPayload) =>
    magicLinkRepository.create(payload);

  const tokenLogin = async (payload: TokenLoginPayload) => {
    startLoading('tokenLogin');

    const result = await magicLinkRepository.tokenLogin(payload);

    if (!result.data?.valid) {
      localStorage.removeItem('sovi-token');
    }

    endLoading('tokenLogin');
  };

  const logout = async () => {
    startLoading('logout');

    localStorage.removeItem('sovi-token');

    await authorizationRepository.logout();

    authorized.value = false;
    user.value = {} as User;

    endLoading('logout');
  };

  const sendLinks = async (payload: SendLinksPayload) => {
    startLoading('sendLinks');

    const response = await magicLinkRepository.sendLinks(payload);

    endLoading('sendLinks');

    return response;
  };

  const companyChangeRequestPending = ref(false);

  const createCompanyChangeRequest = async (
    payload: CreateCompanyChangeRequestPayload,
  ) => {
    startLoading('createCompanyChangeRequest');

    await participantRepository.createCompanyChangeRequest(payload);

    endLoading('createCompanyChangeRequest');
  };

  const checkCompanyChangeRequest = async () => {
    const result = await participantRepository.checkCompanyChangeRequest();

    companyChangeRequestPending.value = result.data?.exists || false;
  };

  return {
    authorized,
    companyChangeRequestPending,
    contractLogos,
    linkParticipantName,
    netsPerson,
    sessionUpdated,
    showSendDialog,
    user,
    userEmail,
    checkCompanyChangeRequest,
    create,
    createCompanyChangeRequest,
    isAuthorized,
    tokenLogin,
    logout,
    sendLinks,
  };
});
