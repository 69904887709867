<template>
  <div class="mt-7">
    <sovi-bread-crumbs class="pb-2" />

    <v-card class="mb-6">
      <sovi-company-information />
    </v-card>

    <sovi-company-logo />
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { useContractStore } from '@/stores/contract';
import { useI18n } from 'vue-i18n';
import { definePage } from 'vue-router/auto';

definePage({
  name: 'CompanySettings',
  meta: {
    hero: true,
    restricted: true,
  },
});

const { setBreadCrumbs } = useContractStore();

const { t } = useI18n();

onBeforeMount(() => {
  setBreadCrumbs([
    {
      id: undefined,
      href: '/company-settings',
      name: t('companySettings'),
      disabled: true,
    },
  ]);
});
</script>

<i18n lang="json">
{
  "fi": {
    "companySettings": "Hallinta"
  },
  "en": {
    "companySettings": "Settings"
  }
}
</i18n>
