<template>
  <div v-if="modelValue" class="fixed inset-0 z-10 backdrop-blur-sm" />

  <div class="fixed inset-0 z-10" :class="{ 'z-10': modelValue }">
    <slot name="target" />

    <div
      ref="popoverRef"
      class="popover absolute z-10 grid min-w-80 cursor-default gap-4 rounded-2xl text-sm"
      :class="[modelValue ? '' : 'hidden', popoverStyles.base]"
    >
      <slot />

      <div
        class="popover__arrow absolute size-0 bg-white after:block after:size-4 after:rotate-45 after:bg-inherit dark:bg-dark-600"
        :class="popoverStyles.arrow"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useElementBounding, useResizeObserver } from '@vueuse/core';

const props = withDefaults(
  defineProps<{
    target: Element | undefined;
    position: { top: string; left: string; bottom: string; right: string };
    top?: boolean;
    bottom?: boolean;
    left?: boolean;
    right?: boolean;
    persistent?: boolean;
  }>(),
  {
    top: false,
    bottom: false,
    left: false,
    right: false,
    persistent: false,
  },
);

const modelValue = defineModel<boolean>({
  default: false,
});

const popoverRef = ref<HTMLDivElement | null>(null);

const popoverStyles = computed(() => {
  const options = {
    ...props,
  };

  return Object.entries(options)
    .filter(([prop, value]) => prop !== 'modelValue' && !!value)
    .reduce(
      (styleMap, [key]) => {
        styleMap.arrow += `${styleMap.arrow.length ? ' ' : ''}popover__arrow--${key}`;
        styleMap.base += `${styleMap.base.length ? ' ' : ''}popover__base--${key}`;

        return styleMap;
      },
      { arrow: '', base: '' },
    );
});

const calculatePosition = () => {
  requestAnimationFrame(() => {
    const popoverOffset = 8;

    const { left: baseLeftPosition, width: baseWidth } =
      popoverRef.value!.getBoundingClientRect();

    const {
      left: targetLeftPosition,
      width: targetWidth,
      height: targetHeight,
    } = useElementBounding(props.target! as HTMLElement);

    const mathMethod = props.left ? Math.max : Math.min;

    const arrowPosition = mathMethod(
      baseWidth - 42,
      targetLeftPosition.value -
        popoverOffset -
        baseLeftPosition +
        targetWidth.value / 2,
    );

    popoverRef.value?.style.setProperty(
      '--popover-arrow-left',
      `${Math.round(arrowPosition)}px`,
    );

    popoverRef.value?.style.setProperty(
      '--popover-bottom',
      `${Math.round(targetHeight.value + popoverOffset)}px`,
    );
  });
};

watch(
  () => props.target,
  () => {
    calculatePosition();
  },
);

useResizeObserver(
  popoverRef,
  () => {
    calculatePosition();
  },
  { box: 'border-box' },
);
</script>

<style>
.popover {
  top: v-bind(props.position.top);
  left: v-bind(props.position.left);
  bottom: v-bind(props.position.bottom);
  right: v-bind(props.position.right);
}
</style>

<style lang="scss">
.popover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  .v-card {
    @apply dark:bg-dark-600 #{!important};
  }

  &__base {
    &--top {
      // @apply bottom-10;
    }

    &--bottom {
      // @apply top-10;
      // top: var(--popover-bottom);
    }

    &--left {
      // @apply -right-2;
    }

    &--right {
      // @apply -left-2;
    }
  }

  &__arrow {
    left: var(--popover-arrow-left, 0);

    &--top {
      @apply bottom-3;
    }

    &--bottom {
      @apply -top-1;
    }
  }
}
</style>
