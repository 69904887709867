<template>
  <sovi-popover
    v-if="currentTourStep"
    v-model="isTourActive"
    :target
    :position="popoverPosition"
    :top="currentTourStep.position.top"
    :bottom="currentTourStep.position.bottom"
    :left="currentTourStep.position.left"
    :right="currentTourStep.position.right"
  >
    <v-card>
      <v-card-title class="overflow-visible text-2xl">
        {{ currentTourStep.title }}
      </v-card-title>

      <v-card-text class="grid gap-4">
        <template
          v-for="(item, index) in currentTourStep.body"
          :key="`onboarding-item-${index}`"
        >
          <ul v-if="Array.isArray(item)" class="ml-4 list-disc">
            <li
              v-for="(row, rowIndex) in item"
              :key="`onboarding-item-${index}--row-${rowIndex}`"
            >
              {{ row }}
            </li>
          </ul>

          <p v-else>{{ item }}</p>
        </template>
      </v-card-text>

      <v-card-actions class="gap-2">
        <template v-if="!isLastStep">
          <v-btn
            variant="text"
            color=""
            size="default"
            @click="endOnboardingTour()"
          >
            {{ t('close') }}
          </v-btn>

          <v-spacer />

          <v-btn
            variant="elevated"
            size="default"
            @click="currentTourStepIndex += 1"
          >
            {{ t('next') }}
          </v-btn>
        </template>

        <template v-else>
          <v-spacer />

          <v-btn variant="elevated" size="default" @click="endOnboardingTour()">
            {{ t('finish') }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </sovi-popover>
</template>

<script setup lang="ts">
import { watch, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useOnboardingStore } from '@/stores/onboarding';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { endOnboardingTour } = useOnboardingStore();

const { currentTourStep, currentTourStepIndex, isTourActive, isLastStep } =
  storeToRefs(useOnboardingStore());

const target = ref<Element | undefined>();

const popoverPosition = ref({
  top: 'auto',
  bottom: 'auto',
  left: 'auto',
  right: 'auto',
});

watch(
  () => currentTourStep.value?.target,
  (step) => {
    if (!step) return;

    requestAnimationFrame(() => {
      positionPopover();
    });
  },
  { immediate: true },
);

const scrollToJustAbove = (element: Element, margin = 20) => {
  const dimensions = element.getBoundingClientRect();

  document
    .querySelector('.v-application')
    ?.scrollTo(window.scrollX, dimensions.top - margin);
};

const positionPopover = () => {
  if (target.value) {
    (target.value as HTMLElement).style.removeProperty('z-index');
    (target.value as HTMLElement).style.removeProperty('pointer-events');
  }

  const el = document.querySelector(currentTourStep.value!.target) || undefined;

  scrollToJustAbove(el as Element);

  const position = el?.getBoundingClientRect();

  if (!position) return;

  const { width } = document.body.getBoundingClientRect();

  target.value = el;

  const offset = 16;

  popoverPosition.value = {
    right: currentTourStep.value?.position.left
      ? `${Math.max(width - position.x - position.width - offset, 20)}px`
      : 'auto',
    left: currentTourStep.value?.position.right
      ? `${Math.max(position.x - offset, 20)}px`
      : 'auto',
    top: currentTourStep.value?.position.bottom
      ? `${Math.max(position.y + position.height + 12, 20)}px`
      : 'auto',
    bottom: currentTourStep.value?.position.top
      ? `${Math.max(position.y - 12, 20)}px`
      : 'auto',
  };
};

onMounted(() => {
  // setTimeout(() => {
  //   positionPopover();
  // }, 4000);
});
</script>

<style lang="scss">
.sovi-onboarding-step {
}
</style>

<i18n lang="json">
{
  "fi": {
    "next": "Seuraava",
    "close": "Sulje",
    "finish": "Ok"
  },
  "en": {
    "next": "Next",
    "close": "Close",
    "finish": "Ok"
  }
}
</i18n>
