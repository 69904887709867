import type { Logo } from './document';
import type { DragItem } from './dragging';

export type Contract = {
  created_at: string;
  folder_id: null | string;
  folder: null | string;
  id: string;
  name: string;
  reference: number;
  role: ContractRole;
  roleId: number;
  hash: string;
  self_sign: number | null;
  self_sign_type: 'private' | 'company' | null;
  status: ContractStatus;
  updated_at: null | string;
  author?: string;
};

export type GetContractPayload = Omit<Contract, 'reference'> & {
  author: string;
  contract_id: number;
  crumbs: Crumb[];
};

export interface Crumb {
  id: number;
  name: string;
  parent_id: null | string;
  participant_id: number;
}

export enum ContractRole {
  Author = 'author',
  Participant = 'participant',
  Observer = 'observer',
}

export enum ContractRoleId {
  Author = 1,
  Participant = 2,
  Observer = 3,
}

export enum ContractStatus {
  Created = 'created',
  Finished = 'finished',
  Sent = 'sent',
  Failed = 'failed',
}

export type Folder = {
  id: string;
  name: string;
  parent_id: null | string;
  type: 'folder';
};

export type GetContractsResponse = {
  total: number;
  folders: Folder[];
  contracts: Contract[];
  crumbs: Crumb[];
};

export type CreateContractPayload = {
  contract: string;
  name: string;
  parentId: string;
};

export type CreateContractResponse = {
  contract: {
    id: string;
    name: string;
    created_at: string;
    roleId: 1;
    role: ContractRole.Author;
  };
};

export type CreateFolderPayload = {
  folder: {
    name: string;
  };
  parentId: null | string;
};

export type CreateFolderResponse = {
  folder: {
    id: string;
  };
};

export type GetLogosResponse = Logo[];

export type UpdateFolderPayload = {
  id: string;
  name: string;
};

export type MessageResponse = {
  message: string;
};

export type UpdateFolderResponse = MessageResponse;

export type DeleteFolderPayload = {
  id: string;
};

export type DeleteFolderResponse = MessageResponse;

export type UpdateContractResponse = {
  contract: Contract[];
};

export type MoveFolderPayload = {
  target: string;
  item: {
    id: string;
    name: string;
    parent_id: null | string;
  };
  type: 'folder' | 'contract';
};

export type SearchContractsResult = {
  contracts: SearchContractsResultItem[];
};

export type SearchContractsResultItemPath = {
  id: number;
  name: string;
  parent_id: null;
  participant_id: number;
};

export type SearchContractsResultItem = {
  id: number | string;
  name: string;
  folderId: number | null;
  type: 'folder' | 'contract';
  path: SearchContractsResultItemPath[];
};

export type ListContractsPayload = {
  folderId?: string | null;
  replaceState?: boolean;
  page?: number;
  limit?: number;
  merge?: boolean;
};

export type FolderMoveDialogParams = {
  show: boolean;
  source: DragItem | null;
  target: DragItem | null;
  type?: 'folder' | 'contract';
};

export type FolderDeleteChildren = {
  contracts: Contract[];
  folders: Folder[];
  crumbs?: Crumb[];
};
